import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

const AboutPage = props => (
  <Layout>
    <Helmet>
      <title>JIT Machine Corp</title>
      <meta name="description" content="JIT Machine About Page" />
    </Helmet>

    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>About</h1>
        </header>
        <div className="content">
          <p>
            Just In Time Machine Corporation is a minority-owned small business
            that provides CNC machining services to a variety of industries.
            <br />
            <br />
            With over 40 years of experience, you can count on us to produce
            complex machined parts using state-of-the-art equipment. We
            specialize in prototype and short production runs and work with all
            materials including stainless and plastics.
            <br />
            <br />
            We are located just outside of Madison WI and serve clients
            throughout the country. Just In Time Machining Corporation will
            quickly turnaround your quotes and parts, so let’s talk.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutPage;
